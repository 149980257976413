/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@bbraun/cortex/styles/cortex";

/* imported, because the component is used as an overlay, so styles need to be defined globally */
@import "app/template/template-detail/template-editor/section-edit/condition-edit/placeholder-edit/placeholder-edit.component.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $bbraun-font-family;
}


$status-completed: #7373737A;
$template-table-heading-1: #00AC76;

@mixin bbraun-medium-subheadline {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

@mixin label {
  border-radius: 10px;
  width: auto; /* Making auto-sizable width */
  height: auto; /* Making auto-sizable height */
  padding: 3px 5px 3px 5px; /* Making space around letters */
}

.spacer {
  flex-grow: 1;
}

.ruler {
  height: 0.1rem;
  width: 100%;
  background-color: $bbraun-gray-30;
}
