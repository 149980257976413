
.placeholder-edit-button {
  background-color: white !important;
}

.placeholder-edit-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .conditions {
    flex-grow: 1;
    overflow: auto;

    .placeholder-line {
      width: 100%;
      flex-wrap: nowrap;

      mat-form-field {
        min-width: 15.625rem;
        flex-grow: 1;
      }
      button {
        margin-top: 1.5rem;
      }
    }

  }

  .format-line {
    align-items: center;
    height: 5.625rem;

    mat-icon {
      padding-left: 0.2rem;
      color: $bbraun-disabled;
    }

    mat-form-field {
      padding-left: 1.5rem;
    }
  }

  .dialog-buttons {
    .mat-mdc-form-field-bottom-align {
      display: none;
    }
    align-items: end;
    button {
      margin-left: 1.5rem;
    }

    margin-left: auto;
  }

}
